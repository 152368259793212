import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExpensenotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/expense-notes', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchExpensenote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/expense-notes/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateExpensenote(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/expense-notes/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addExpensenote(ctx, expensenoteData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/expense-notes', expensenoteData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteExpensenote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/expense-notes/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchExpensenoteTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/expense-note-types', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addExpensenoteType(ctx, expensenotesTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/expense-note-types', expensenotesTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteExpensenoteType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/expense-note-types/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addExpensenoteAttachments(ctx, expensenotesAttachmentsData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/expense-note-attachments', expensenotesAttachmentsData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteExpensenoteAttachment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/expense-note-attachments/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    exportCsv(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/expense-notes/export', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    downloadAttachments(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/expense-notes/${id}/attachments`, { responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
