import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBusinesstrips(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/business-trips', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchBusinesstripsByUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/business-trips/by-user-expense', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchBusinesstrip(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business-trips/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateBusinesstrip(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/business-trips/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addBusinesstrip(ctx, BusinesstripData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/business-trips', BusinesstripData)
          .then((response) => resolve(response))
          .catch((error) => {
            console.error('addBusinesstrip error:', error)
            reject(error)
          })
      })
    },
    deleteBusinesstrip(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/business-trips/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    exportCsv(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/business-trips/export', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
